<script setup>
  import { computed, reactive, ref } from '@vue/reactivity'
  import { useRoute } from "vue-router";
  import { watch } from 'vue'
  import PriceListsHeader from './PriceListsHeader.vue';
  import useGetPricelists from '../../../hooks/price-lists/useGetPricelists';
  import PriceListsList from './PriceListsList.vue';
  import InlinePreloader from '../../../components/parts/inlinePreloader.vue';

  import InfiniteLoading from "v3-infinite-loading";
  import "v3-infinite-loading/lib/style.css";

  import NoResults from './../../../components/NoResults.vue'
  const route = useRoute();
  const routeQuery = computed(() => { 
    return route.query
  });
  const queryKeyParams = ref({...routeQuery.value, id: 1})
  const { data, isLoading, isFetching, isRefetching, hasNextPage, fetchNextPage } = useGetPricelists(queryKeyParams)

  watch(routeQuery, () => {
    queryKeyParams.value = {...routeQuery.value, id: queryKeyParams.value.id + 1}
  }, {deep: false})



  const resultData = computed(() => {
    const resultArray = [];
    for (let page in data?.value?.pages) {
      resultArray.push(...data.value.pages[page].data);
    }
    return resultArray;
  });


  const load = reactive(isLoading)

  const startInfiniteLoading = async ($state) => {
      await fetchNextPage.value()
      $state.loaded();
      if(!hasNextPage) {
        $state.complete();
      }   
  };
</script>

<template>
  <PriceListsHeader></PriceListsHeader>
  <InlinePreloader v-if="isLoading" message="Loading..."></InlinePreloader>
  <div v-else class="price-list-wrapper">
    <PriceListsList :price-lists="resultData" :class="{'tw-animate-pulse': isRefetching}"></PriceListsList>
  </div>

  <NoResults class="tw-h-60" v-if="!isLoading && !resultData.length"/>
  
  
  <div class="loader tw-flex tw-mt-4 tw-items-center tw-justify-center">
    <InfiniteLoading
      :firstLoad="false"
      @infinite="startInfiniteLoading"
    />
  </div>

</template>

<style scoped>

</style>
